.icons {
  &.chevron {
    $size: 1em;
    height: $size * 1.5;
    width: $size * 2;
    display: flex;

    &:before {
      border-style: solid;
      border-width: $size / 15 $size / 15 0 0;
      content: '';
      display: inline-block;
      height: $size;
      left: 0;
      position: relative;
      top: 0;
      transform: rotate(-45deg);
      vertical-align: top;
      width: $size;
      margin: 0 auto;
    }

    &.right:before {
      transform: rotate(45deg);
    }

    &.bottom:before {
      transform: rotate(135deg);
    }

    &.left:before {
      transform: rotate(-135deg);
    }
  }

  &.arrow {
    width: 1.6em;
    height: 1.6em;
    background-repeat: no-repeat;

    &.left {
      background-image: url('../../icons/arrow-left.svg');
    }

    &.right {
      background-image: url('../../icons/arrow-right.svg');
    }
  }
}
