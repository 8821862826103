.magazine-wrapper {
  @include media-breakpoint-up(lg) {
    display: flex;
    flex-direction: row;
  }

  .column-image {
    z-index: 1;
    overflow: hidden;
    max-height: 100vh;

    img {
      width: auto;
      //height: 100%;
      max-height: 100%;
      object-fit: cover;
    }

    @include media-breakpoint-down(md) {
      max-height: 70vh;

      img {
        //min-height: 20vh;
      }
    }

    @include media-breakpoint-up(lg) {
      width: 50%;
      position: sticky;
      top: 0;

      img {
        height: 100vh;
        width: 100%;
      }
    }

    @include ie() {
      img {
        width: auto !important;

        @include media-breakpoint-down(md) {
          width: 100% !important;
          height: auto !important;
        }
      }
    }
  }

  .column-content {
    width: 50%;
    overflow: hidden;
    padding: 7.5rem 5vw 3rem 5vw;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(md) {
      padding: 5vw;
    }

    @include media-breakpoint-down(sm) {
      padding: 25px;
    }

    > .container {
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1 1 auto;

      > * {
        // Fix bug in IE where texts would not be wrapped
        max-width: 100%;
      }
    }

    // @include media-breakpoint-up(xl) {
    //   padding: 7.5rem 5vw 5rem 8vw;
    // }

    // @include media-breakpoint-down(md) {
    //   padding: 5rem 10vw;
    // }

    // @include media-breakpoint-between(md, lg) {
    //   width: 60%;
    //   min-width: 700px;
    // }

    @include media-breakpoint-down(md) {
      width: 100%;
    }

    @include media-breakpoint-up(lg) {
      width: 58%;
      min-width: 700px;
    }

    @include media-breakpoint-up(xl) {
      width: 50%;
      min-width: 700px;
      padding: 7.5rem 5vw 3rem 7vw;
    }
  }
}
