.vignette-top {
  position: relative;

  &:after {
    content: "";
    display: block;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0, transparent 250px, transparent);
    pointer-events: none;
  }
}
