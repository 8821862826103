footer {
  position: relative;
  z-index: 10;
  background-color: $dark;
  color: $white;
  text-transform: lowercase;
  padding: 3rem 1rem;

  nav {
    width: 100%;
    max-width: 1000px;

    ul {
      display: flex;
      justify-content: space-between;

      &:before, &:after {
        content: '';
        display: block;
      }

      @include media-breakpoint-down(sm) {
        flex-direction: column;

        li {
          align-self: center;
        }
      }
    }
  }

  a {
    color: $white;
    transition: $transition-base;

    &.nav-link:after {
      content: '';
      display: block;
      width: 0%;
      height: 1px;
      transition: $transition-base;
      background-color: $white;
      margin-bottom: -1px;
    }
  }

  a:hover {
    color: $white;

    &.nav-link:after {
      width: 100%;
    }
  }
}
