@import "@/scss/_vue-globals.scss";














































$menu-button-width: 53px;
$menu-button-thickness: 2px;
$menu-button-height: 50px;

.vue--burger-button {
    outline: none;
    border: none;
    background: transparent !important;
    padding: 0;

    &, &:focus {
        outline: none;
    }

    &.white .bar {
        background-color: $white;
    }

    &.primary .bar {
        background-color: $primary;
    }

    &.secondary .bar {
        background-color: $secondary;
    }

    &.white.collapsed .bar {
        background-color: $white;
    }

    &.primary.collapsed .bar {
        background-color: $primary;

        @include media-breakpoint-down(md) {
            background-color: $white;
        }
    }

    &.secondary.collapsed .bar {
        background-color: $secondary;
    }


    .bar {
        width: $menu-button-width * 0.9;
        height: $menu-button-thickness;
        display: block;
        transition: all .3s;
        border-radius: $menu-button-thickness;

        & + .bar {
            margin-top: #{($menu-button-height - ($menu-button-thickness * 3)) / 3};
        }

        &.top {
            transform: rotate(45deg);
            transform-origin: 0%;
        }

        &.middle {
            opacity: 0;
            width: 0;
        }

        &.bottom {
            transform: rotate(-45deg);
            transform-origin: 0%;
        }
    }

    &.collapsed {
        .bar {
            width: $menu-button-width;

            &.top {
                transform: rotate(0);
            }

            &.middle {
                opacity: 1;
                width: 70%;
            }

            &.bottom {
                transform: rotate(0);
            }
        }
    }

    /*        @include for-mobile {
                transform: scale(0.8);
            }*/
}
