body[data-template="wines/detail"] {
  .product-image {
    height: auto;
    max-height: 45vh;
    margin: 2rem 0 1rem 0;

    @include media-breakpoint-up(md) {
      max-height: 55vh;
    }

    $map: (
            sm: 0vw,
            xl: 6vw
    );

    @each $size, $offset in $map {
      @include media-breakpoint-up($size) {
        margin-left: -$offset;
        max-width: calc(100% + #{$offset});
      }
    }
  }

  .wine-navigation {
    display: flex;
    justify-content: space-between;
    font-size: $font-size-sm;
    margin-top: 3rem;
    align-self: stretch;
    align-items: flex-end;
    flex: 1 1 auto;

    a {
      display: flex;

      * + * {
        margin-left: 1rem;
      }
    }

    @include media-breakpoint-down(sm) {
      a {
        flex-direction: column;

        &:last-child {
          flex-direction: column-reverse;
          align-items: flex-end;
          text-align: right;
        }

        * + * {
          margin-left: 0;
        }

        span {
          margin-top: 0.5rem;
        }
      }
    }
  }
}
