body, input, textarea, button {
  font-style: italic;
}

h1,
h2,
h3,
a {
  text-transform: lowercase;
}

.pre-arrow::before {
  content: '> ';
}