.fade {
  &-enter-active, &-leave-active {
    transition: opacity .5s;
  }

  &-enter, &-leave-to {
    opacity: 0;
  }
}

.slide-down {
  &-enter-active, &-leave-active {
    transition: transform .5s;
  }

  &-enter, &-leave-to {
    transform: translateY(-100%);
  }
}
