.contact {
  text-transform: lowercase;

  .join {
    @include media-breakpoint-down(md) {
      span {
        display: block;
      }
    }

    @include media-breakpoint-up(lg) {
      span:not(:last-child):after {
        content: ', ';
      }

      span:not(:first-child) {
        white-space: nowrap;
      }
    }
  }
}
