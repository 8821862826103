body[data-template="pages/order"] {
  .navbar.collapsed .brand a {
    color: $primary;
  }

  .vue--burger-button.collapsed .bar {
    background-color: $primary !important;
  }

  main {
    padding-top: 5rem;
  }

  .container-fluid {
    max-width: 1600px;
  }

  .product-image {
    position: relative;
    margin-top: 50px;
    margin-left: auto;
    margin-right: auto;
    min-width: 120px;
    width: 100%;
    cursor: pointer;

    &:hover {
      .image-background {
        background: $gray-100;
      }

      .image-wrapper {
        transform: scale(1.07);
      }
    }

    .product-hint {
      position: absolute;
      bottom: 6%;
      left: 6%;
      z-index: 999;
      background: $primary;
      color: $white;
      width: 40%;
      height: 40%;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      overflow: hidden;
      transform: rotate(-12deg);

      > span {
        width: 80%;
        text-align: center;
        max-height: 80%;
      }
    }

    .image-background {
      background: $gray-200;
      padding-bottom: 100%;
      transition: $transition-base;
    }

    .image-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      text-align: center;
      transition: $transition-base;

      img {
        margin: -5% auto 0 auto;
        height: 100%;
        width: auto;
      }
    }
  }

  .products-list {
    margin-left: -25px;
    margin-right: -25px;

    .product {
      padding-left: 25px;
      padding-right: 25px;
      overflow: hidden;
    }
  }

  input[name^="wines"] {
    width: 35px;
    text-align: center;
    padding: 0;
  }

  .form-wrapper {
    margin-top: 39px;
  }
}
