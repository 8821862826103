.wine-list-item {
  .row[aria-expanded=true] .icons.chevron {
    transform: rotate(180deg);
  }

  .icons.chevron {
    transform: translateY(0px);
    transition: $transition-base;
  }

  &:hover .icons.chevron {
    transform: translateY(3px);
  }
}
